<template>
  <div class="lg:card-base gap-12 md:flex lg:p-16">
    <swiper
      v-if="references?.length"
      v-bind="swiperConfig"
    >
      <swiper-slide
        v-for="({title, text, active_image, client, profession}, index) in references"
        :key="index"
      >
        <div class="grid gap-8 lg:grid-cols-2 lg:gap-12">
          <div class="relative aspect-[443/480] overflow-hidden rounded-xl">
            <NuxtPicture
              v-if="active_image"
              :src="active_image.url"
              :alt="title"
              :img-attrs="{class: 'h-full w-full object-cover'}"
              sizes="1200 md:700px lg:900px"
            />
          </div>
          <div class="space-y-8">
            <blockquote class="line-clamp-6 leading-6 lg:text-lg lg:leading-loose">
              {{ text }}
            </blockquote>
            <template v-if="client">
              <NuxtLink
                class="btn btn-lg btn-primary"
                :to="`/${client.slug}`"
              >
                Werken bij {{ client.alias ?? client.title }}
              </NuxtLink>
              <div class="text-sm lg:text-base">
                {{ title }}, <div class="text-gray-lighter lg:inline">
                  {{ profession.title }}
                </div>
              </div>
              <div
                v-if="client.logo"
                class="mt-8"
              >
                <NuxtPicture
                  :img-attrs="{class: 'w-20'}"
                  :src="client.logo.url"
                  :alt="client.title"
                  sizes="100px"
                />
              </div>
            </template>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div>
      <div class="flex size-full divide-x divide-gray-lightest overflow-hidden rounded-xl border border-gray-lightest lg:w-auto lg:flex-col-reverse lg:items-end lg:divide-x-0 lg:divide-y lg:divide-y-reverse">
        <button
          id="swiper-navigation-next"
          class="flex basis-1/2 items-center justify-center bg-white p-4"
        >
          <span class="sr-only">Volgende</span>
          <IconsControlNext class="rotate-180 stroke-gray" />
        </button>
        <button
          id="swiper-navigation-prev"
          class="flex basis-1/2 items-center justify-center bg-white p-4"
        >
          <span class="sr-only">Vorige</span>
          <IconsControlNext class="stroke-gray" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation} from 'swiper';
import type {Referentie} from '~/graphql/graphql';
import 'swiper/css';

const swiperConfig = {
  slidesPerView: 1,
  spaceBetween: 8,
  centeredSlides: true,
  loop: true,
  modules: [Navigation],
  navigation: {
    prevEl: '#swiper-navigation-prev',
    nextEl: '#swiper-navigation-next',
  },
};

defineProps<{
  references: Referentie[];
}>();
</script>
